import { Classes } from "@blueprintjs/core";
import React, { useMemo } from "react";
import styled from "styled-components";

import { HexTooltip } from "../../hex-components";

const Container = styled.div`
  display: inherit;
  flex: none;
  align-items: center;

  .${Classes.ICON} + span {
    margin-left: 5px;
  }
`;

const InnerElementsContainer = styled.div`
  display: flex;
  align-items: center;

  &&&& {
    .${Classes.ICON} {
      margin: 0;
    }
  }
`;

const Text = styled.span`
  display: inline-flex;
  min-width: 0;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  line-height: 18px;
`;

const PermissionTooltip = styled(HexTooltip)`
  && {
    display: inherit;
  }
`;

const TooltipContent = styled.div`
  text-align: center;
`;

const TooltipLabel = styled.span`
  color: ${({ theme }) => theme.fontColor.MUTED};
`;

export interface HexPermissionSummaryContainerProps {
  tooltipText: string;
  text: string;
  minimal?: "icon" | "text";
  icon: JSX.Element;
}

export const HexPermissionSummaryContainer: React.ComponentType<HexPermissionSummaryContainerProps> =
  React.memo(function HexPermissionSummary({
    icon,
    minimal,
    text,
    tooltipText,
  }) {
    const innerElements = useMemo(() => {
      if (minimal === "text") {
        return <Text>{text}</Text>;
      } else if (minimal === "icon") {
        return icon;
      } else {
        return (
          <>
            {icon}
            <Text>
              <span className={Classes.TEXT_OVERFLOW_ELLIPSIS}>{text}</span>
            </Text>
          </>
        );
      }
    }, [icon, minimal, text]);

    return (
      <Container>
        <PermissionTooltip
          content={
            <TooltipContent>
              <TooltipLabel>Permissions:</TooltipLabel> {tooltipText}
            </TooltipContent>
          }
          // Add a delay if it's not minimal
          hoverOpenDelay={minimal ? 0 : 400}
          placement="bottom"
        >
          <InnerElementsContainer>{innerElements}</InnerElementsContainer>
        </PermissionTooltip>
      </Container>
    );
  });
