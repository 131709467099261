import { gql, useApolloClient } from "@apollo/client";
import { HexId } from "@hex/common";
import { useCallback } from "react";

import {
  HexListFragment,
  HexListFragmentDoc,
} from "../../../hex-list/HexList.generated.js";
import {
  GetStarredProjectsForSidebarDocument,
  GetStarredProjectsForSidebarQuery,
  GetStarredProjectsForSidebarQueryVariables,
} from "../../../home/sidebar/FavoriteProjects.generated.js";

import {
  StarHexMutation,
  StarHexMutationVariables,
  useStarHexMutation,
} from "./useStarHex.generated.js";

gql`
  mutation starHex($hexId: HexId!, $starred: Boolean!) {
    starHex(hexId: $hexId, starred: $starred) {
      id
      starredByViewer
    }
  }
`;

export const useStarHex = (
  hexId: HexId,
  handleError: (starred: StarHexMutation["starHex"]["starredByViewer"]) => void,
  handleUpdate?: (
    hexId: HexId,
    starred: StarHexMutation["starHex"]["starredByViewer"],
  ) => void,
): ((starred: StarHexMutation["starHex"]["starredByViewer"]) => void) => {
  const [starHex] = useStarHexMutation();
  const client = useApolloClient();

  const handleStarChange = useCallback(
    (starred) => {
      const variables: StarHexMutationVariables = {
        hexId,
        starred,
      };

      starHex({
        variables,
        optimisticResponse: {
          __typename: "Mutation",
          starHex: {
            id: hexId,
            __typename: "Hex",
            starredByViewer: variables.starred,
          },
        },
        update: (_, { data }) => {
          if (data != null) {
            handleUpdate?.(hexId, data.starHex.starredByViewer);
          }
          // Update the cache for the starred hexes query in the sidebar
          const hexFragment = client.readFragment<HexListFragment>({
            fragment: HexListFragmentDoc,
            fragmentName: "HexListFragment",
            id: client.cache.identify({ __typename: "Hex", id: hexId }),
          });
          const queryResult = client.readQuery<
            GetStarredProjectsForSidebarQuery,
            GetStarredProjectsForSidebarQueryVariables
          >({
            query: GetStarredProjectsForSidebarDocument,
          });
          if (queryResult == null || hexFragment == null) {
            return;
          }
          const updatedHex = {
            ...hexFragment,
            starredByViewer: starred,
          };
          let newHexes = [...queryResult.hexes.edges];
          if (starred) {
            newHexes.push({
              __typename: "HexesEdge",
              node: {
                ...updatedHex,
              },
            });
          } else {
            newHexes = newHexes.filter((h) => h.node.id !== hexId);
          }
          client.writeQuery<
            GetStarredProjectsForSidebarQuery,
            GetStarredProjectsForSidebarQueryVariables
          >({
            query: GetStarredProjectsForSidebarDocument,
            data: {
              ...queryResult,
              __typename: "Query",
              hexes: {
                ...queryResult.hexes,
                edges: newHexes,
              },
            },
          });
        },
      }).catch((e) => {
        console.error(e);
        handleError(!starred);
      });
    },
    [hexId, starHex, client, handleUpdate, handleError],
  );

  return handleStarChange;
};
