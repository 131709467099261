import { HexType } from "@hex/common";

import { useFeatureGates } from "../../../feature-gate/FeatureGateContext.js";
import { HexRow2Fragment } from "../HexRow2.generated.js";

/**
 * Returns true of the following criteria are met:
 * - feature flag is enabled
 * - hexType is project
 * - hex is not trashed
 * - hex has been published
 */
export const shouldShowViewPublishedAppStats = (
  hex: HexRow2Fragment,
): boolean => {
  return hex.hexType === HexType.PROJECT && hex.lastPublishedVersion != null;
};
export const useShouldShowViewPublishedAppStats = (
  hex: HexRow2Fragment,
): boolean => {
  return shouldShowViewPublishedAppStats(hex);
};

/**
 * Returns true of the following criteria are met:
 * - feature gate (feature tier) is enabled
 */
export const useShouldShowAdvancedProjectViewStats = (): boolean => {
  const featureGates = useFeatureGates();

  return featureGates.advancedProjectViewStats;
};
