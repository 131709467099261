import { gql } from "@apollo/client";
import { Intent } from "@blueprintjs/core";
import {
  CollectionHexLinkId,
  CollectionId,
  HexType,
  ProjectRole,
} from "@hex/common";
import React, { useCallback } from "react";

import { useHexFlag } from "../../../util/useHexFlags.js";
import { useToaster } from "../../common/Toasts.js";
import { RoleDropdown } from "../../share/RoleDropdown.js";

import { useUpdateCollectionProjectRoleMutation } from "./CollectionProjectRoleDropdown.generated.js";
import { HexRow2Fragment } from "./HexRow2.generated.js";

gql`
  mutation UpdateCollectionProjectRole(
    $projectRole: ProjectRole
    $hexId: HexId!
    $collectionId: CollectionId!
  ) {
    updateCollectionProjectRole(
      projectRole: $projectRole
      hexId: $hexId
      collectionId: $collectionId
    ) {
      id
      projectRole
    }
  }
`;

interface CollectionProjectRoleDropdownProps {
  hex: Pick<
    HexRow2Fragment,
    "id" | "canShare" | "hexType" | "maxGrantableRole"
  >;
  projectRole: ProjectRole | null;
  canEditCollection: boolean;
  collectionId: CollectionId;
  collectionHexLinkId: CollectionHexLinkId;
  onChange?: (role: ProjectRole | null) => void;
  className?: string;
}

export const CollectionProjectRoleDropdown =
  React.memo<CollectionProjectRoleDropdownProps>(
    function CollectionProjectRoleDropdown({
      canEditCollection,
      className,
      collectionHexLinkId,
      collectionId,
      hex,
      onChange,
      projectRole,
    }) {
      const toaster = useToaster();
      const isComponent = hex.hexType === HexType.COMPONENT;
      const preventNav = useCallback((evt) => {
        evt.preventDefault();
      }, []);
      const explorerRoleCanViewChange = useHexFlag(
        "explorer-role-can-view-change",
      );

      const onSuccess = useCallback(
        (newRole) => {
          onChange?.(newRole);
        },
        [onChange],
      );

      const onError = useCallback(() => {
        onChange?.(projectRole);
      }, [onChange, projectRole]);

      const [updateCollectionProjectRoleMutation] =
        useUpdateCollectionProjectRoleMutation();
      const updateProjectRoleForProjectInCollection = useCallback(
        async (role: ProjectRole | null) => {
          try {
            onSuccess(role);
            await updateCollectionProjectRoleMutation({
              variables: {
                projectRole: role,
                hexId: hex.id,
                collectionId,
              },
              optimisticResponse: {
                __typename: "Mutation",
                updateCollectionProjectRole: {
                  __typename: "CollectionHexLink",
                  id: collectionHexLinkId,
                  projectRole: role,
                },
              },
            });
          } catch (e) {
            console.error(e);
            onError();
            toaster.show({
              message: "Failed to update collection access. Please try again.",
              intent: Intent.DANGER,
            });
          }
        },
        [
          hex.id,
          collectionId,
          updateCollectionProjectRoleMutation,
          onSuccess,
          onError,
          toaster,
          collectionHexLinkId,
        ],
      );

      const handleOnSelectRole = useCallback(
        (selectedRole: ProjectRole | null) =>
          updateProjectRoleForProjectInCollection(selectedRole),

        [updateProjectRoleForProjectInCollection],
      );

      return canEditCollection ? (
        <div className={className} onClick={preventNav}>
          <RoleDropdown
            canShare={hex.canShare}
            disabled={!canEditCollection}
            explorerRoleCanViewChange={explorerRoleCanViewChange}
            isComponent={isComponent}
            isInCollectionsContext={true}
            labelPrefix="Members: "
            maxRole={hex.maxGrantableRole ?? ProjectRole.EDITOR}
            minRole={isComponent ? ProjectRole.VIEWER : ProjectRole.APP_USER}
            selectedRole={projectRole}
            small={true}
            onSelectRole={handleOnSelectRole}
          />
        </div>
      ) : null;
    },
  );
