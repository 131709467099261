import { rgba } from "polished";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled, { css } from "styled-components";

import { HexSpinner } from "../../hex-components/index.js";

export interface HexList2InfiniteScrollOptions {
  hasMoreData: boolean;
  loadMoreData: () => Promise<unknown>;
}

export const HexList2InfiniteScrollContainer: React.ComponentType<{
  children: React.ReactElement;
  hasMoreData: HexList2InfiniteScrollOptions["hasMoreData"];
  length: number;
  loadMoreData: HexList2InfiniteScrollOptions["loadMoreData"] | null;
  scrollableTargetId: string;
}> = ({
  children,
  hasMoreData = false,
  length,
  loadMoreData,
  scrollableTargetId,
}) => {
  const shouldRenderInfiniteScroll =
    loadMoreData != null && scrollableTargetId != null;

  return shouldRenderInfiniteScroll ? (
    <InfiniteScroll
      dataLength={length}
      hasMore={hasMoreData}
      loader={<InfiniteScrollLoadingSpinner />}
      next={loadMoreData}
      scrollableTarget={scrollableTargetId}
    >
      <OffsetPaddingWrapper>{children}</OffsetPaddingWrapper>
    </InfiniteScroll>
  ) : (
    children
  );
};

/** These styles override our global scrollbar styles to allow for default show/hide behavior.
 * See [linear ticket WORK-823 for more informaiton](https://linear.app/hex/issue/WORK-823/scrollbars-should-be-disabled-until-scroll-interaction-throughout-our).
 */
export const scrollStyleOverride = css`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    height: unset;
    width: unset;
  }
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => rgba(theme.iconColor, 0.4)} transparent;
  padding-right: 5px;
  margin-top: 2px;
`;

export const HexList2ScrollableArea = styled.div`
  /* If the scroll container has elements with box shadows,
  this prevents them from being cut off by the lack of overflow visibility
  https://medium.com/@timothyde/using-overflow-y-while-retaining-shadows-de5938f8fe1b */
  margin: -4px;
  padding: 4px;

  ${scrollStyleOverride}
`;

const InfiniteScrollLoadingSpinner = styled(HexSpinner)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const OffsetPaddingWrapper = styled.div`
  padding: 0 4px 6px 4px;
  margin-bottom: 10px;
`;
