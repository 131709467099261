import {
  DateTimeString,
  HexColor,
  HexType,
  ProjectRole,
  SpecialVersionType,
  ThemeType,
  assertNever,
  convertRichTextToPlainText,
  isProjectRoleSuperset,
  projectRoleBetweenBounds,
} from "@hex/common";
import React from "react";

import {
  MinimalCategory,
  MinimalStatus,
} from "../../../hooks/useProjectLabelsForHex.js";
import { Routes } from "../../../route/routes.js";
import { HexPermissionSummaryFragment } from "../../hex/HexPermissionSummary.generated.js";
import { HexRowCurrentDraftFragment } from "../../hex-list/HexRow.generated.js";
import { ViewCountDuration } from "../../hex-list/ViewCountDuration.js";
import {
  CollectionIcon,
  GlobeIcon,
  LockIcon,
  UserIcon,
  WorkspaceIcon,
} from "../../icons/CustomIcons.js";
import { CollectionHexLink2Fragment } from "../HexList2.generated.js";

import {
  HexRow2Fragment,
  HexRowLastPublishedVersion2Fragment,
} from "./HexRow2.generated.js";
import { UnknownHexRowFragment } from "./UnknownHexRow.generated.js";

export const isCollectionHexLink = (
  row: HexRow2Fragment | CollectionHexLink2Fragment | UnknownHexRowFragment,
): row is CollectionHexLink2Fragment => row.__typename === "CollectionHexLink";

export const isUnknownHex = (
  hex: HexRow2Fragment | CollectionHexLink2Fragment | UnknownHexRowFragment,
): hex is UnknownHexRowFragment => {
  return hex.__typename === "UnknownHex";
};

interface HexComponentRowFragment extends HexRow2Fragment {
  hexType: "COMPONENT";
}

export const isHexComponent = (
  hex: Pick<HexRow2Fragment, "hexType">,
): hex is HexComponentRowFragment => hex.hexType === HexType.COMPONENT;

const canViewLogic = (hex: Pick<HexRow2Fragment, "canViewLogic">): boolean =>
  hex.canViewLogic;
export const isTrashed = (hex: HexRow2Fragment): boolean =>
  hex.trashDate != null;

export const getHexRoute = (
  hex: Pick<HexRow2Fragment, "hexType" | "canViewLogic">,
  isPublished: boolean,
): typeof Routes.COMPONENT | typeof Routes.LOGIC | typeof Routes.APP => {
  if (isHexComponent(hex)) {
    return Routes.COMPONENT;
  }

  if (canViewLogic(hex) && !isPublished) {
    return Routes.LOGIC;
  }

  return Routes.APP;
};

export const getHexSpecialVersionType = (
  hex: Pick<HexRow2Fragment, "hexType" | "effectiveRole" | "canViewLogic">,
  isPublished: boolean,
): SpecialVersionType => {
  if (isHexComponent(hex)) {
    return SpecialVersionType.DRAFT;
  }

  if ((hex.effectiveRole && !hex.canViewLogic) || isPublished) {
    return SpecialVersionType.LAST_PUBLISHED;
  }

  return SpecialVersionType.DRAFT;
};

export const getHexVersionForMetadata = (
  hex: HexRow2Fragment,
): HexRowLastPublishedVersion2Fragment | HexRowCurrentDraftFragment => {
  return hex.lastPublishedVersion != null && hex.hexType !== HexType.COMPONENT
    ? hex.lastPublishedVersion
    : hex.currentDraft;
};

interface HexMetadata {
  categories: readonly MinimalCategory[];
  description: string | null;
  status: MinimalStatus | null;
  title: string;
  screenshotUrl: string | null;
}

export const getHexMetadata = (
  hex: HexRow2Fragment,
  currentTheme: ThemeType,
): HexMetadata => {
  const { categories, description, status, title } = hex;
  const metadataVersion = getHexVersionForMetadata(hex);
  let screenshotUrl = null;
  if ("screenshotUrls" in metadataVersion) {
    screenshotUrl = metadataVersion.screenshotUrls?.[currentTheme] ?? null;
  }
  return {
    categories,
    description:
      // rich text could have a lot of extra newlines at the end, which might be useful if you want to save/restore the same state, but isn't useful for previews
      description != null
        ? convertRichTextToPlainText(description).trim()
        : null,
    status,
    title,
    screenshotUrl,
  };
};

export const getCreatorName = (
  { creator: { email, name } }: Pick<HexRow2Fragment, "creator">,
  fallback = "",
): string => name || email || fallback;

export const getOwnerName = (
  { owner }: Pick<HexRow2Fragment, "owner">,
  fallback = "",
): string => owner?.name || owner?.email || fallback;

const OPTIONS: Intl.DateTimeFormatOptions = {
  month: "long",
  day: "numeric",
  year: "numeric",
};

export const formatDateString = (
  dateString: DateTimeString,
  options = OPTIONS,
): string => new Date(dateString).toLocaleDateString("en-US", options);

export const getHexInfoSummaryDates = (
  hex: HexRow2Fragment | UnknownHexRowFragment,
  formatted = true,
): {
  dateCreated: string | null;
  dateLastEdited: string;
  dateTrashed: string | null;
  dateLastViewedByMe: string | null;
} => {
  const dateCreated =
    hex.__typename === "UnknownHex" ? null : formatDateString(hex.dateCreated);
  const dateTrashed =
    "trashDate" in hex && hex.trashDate != null
      ? formatDateString(hex.trashDate)
      : null;
  const dateLastViewedByMe =
    hex.__typename === "UnknownHex" || hex.lastViewedByMe == null
      ? null
      : hex.lastViewedByMe;
  return {
    dateCreated,
    dateLastEdited: formatted
      ? formatDateString(hex.userUpdatedDate, OPTIONS)
      : hex.userUpdatedDate,
    dateTrashed,
    dateLastViewedByMe,
  };
};

export const getDateCreated = (
  hex: HexRow2Fragment | UnknownHexRowFragment,
  formatted = true,
): string | null => {
  const dateCreated = hex.__typename === "UnknownHex" ? null : hex.dateCreated;

  return formatted && dateCreated != null
    ? formatDateString(dateCreated, OPTIONS)
    : dateCreated;
};

export const getDateArchived = (
  hex: HexRow2Fragment | UnknownHexRowFragment,
  formatted = true,
): string | null => {
  const dateArchived =
    hex.__typename === "UnknownHex" ? null : hex.archivedDate;

  return formatted && dateArchived != null
    ? formatDateString(dateArchived, OPTIONS)
    : dateArchived;
};

export const getDateLastEdited = (
  hex: HexRow2Fragment | UnknownHexRowFragment,
  formatted = true,
): string => {
  return formatted
    ? formatDateString(hex.userUpdatedDate, OPTIONS)
    : hex.userUpdatedDate;
};

export const getDateLastViewedByMe = (
  hex: HexRow2Fragment,
  formatted = true,
): string | null => {
  return formatted && hex.lastViewedByMe != null
    ? formatDateString(hex.lastViewedByMe, OPTIONS)
    : hex.lastViewedByMe;
};

export const getDateTrashed = (
  hex: HexRow2Fragment | UnknownHexRowFragment,
  formatted = true,
): string | null => {
  const dateTrashed =
    "trashDate" in hex && hex.trashDate != null ? hex.trashDate : null;

  return formatted && dateTrashed != null
    ? formatDateString(dateTrashed, OPTIONS)
    : dateTrashed;
};

export const getDatePublishedAppLastViewed = (
  hex: HexRow2Fragment,
  formatted = true,
): string | null => {
  const datePublishedAppLastViewed =
    hex.lastPublishedVersion != null &&
    hex?.projectAnalytics?.lastPublishedViewedDate != null
      ? hex.projectAnalytics.lastPublishedViewedDate
      : null;

  return formatted && datePublishedAppLastViewed != null
    ? formatDateString(datePublishedAppLastViewed, OPTIONS)
    : datePublishedAppLastViewed;
};

export const getDateLastPublished = (
  hex: HexRow2Fragment,
  formatted = true,
): string | null => {
  const dateLastPublished =
    hex.lastPublishedVersion != null
      ? hex.lastPublishedVersion!.dateCreated // checked by isPublished function
      : null;

  return formatted && dateLastPublished != null
    ? formatDateString(dateLastPublished, OPTIONS)
    : dateLastPublished;
};

export const getViewCount = (
  viewCountDuration: ViewCountDuration,
  { projectAnalytics, totalViewCount }: HexRow2Fragment,
): number => {
  switch (viewCountDuration) {
    case ViewCountDuration.ONE_WEEK:
      return projectAnalytics?.totalViewsLast7Days ?? 0;
    case ViewCountDuration.TWO_WEEKS:
      return projectAnalytics?.totalViewsLast14Days ?? 0;
    case ViewCountDuration.ALL_TIME:
      return totalViewCount ?? 0;
    case ViewCountDuration.ONE_MONTH:
      return projectAnalytics?.totalViewsLast30Days ?? 0;
    default:
      assertNever(viewCountDuration, viewCountDuration);
  }
};

export const getPublishedAppSummary = (
  hex: HexRow2Fragment,
): {
  totalAppViews: number | null;
  datePublishedAppLastViewed: string | null;
  dateLastPublished: string | null;
} => {
  const isPublished = hex.lastPublishedVersion != null;

  const totalAppViews = isPublished ? hex.totalViewCount : null;
  const datePublishedAppLastViewed =
    isPublished && hex?.projectAnalytics?.lastPublishedViewedDate != null
      ? formatDateString(hex.projectAnalytics.lastPublishedViewedDate)
      : null;

  const dateLastPublished = isPublished
    ? formatDateString(hex.lastPublishedVersion!.dateCreated) // checked by isPublished function
    : null;

  return {
    totalAppViews,
    datePublishedAppLastViewed,
    dateLastPublished,
  };
};

export interface PermissionSummary {
  icon: JSX.Element;
  text: string;
  tooltipText: string;
}
interface GetHexPermissionSummaryArgs extends HexPermissionSummaryFragment {
  // what role and above should be included in the summary
  summaryRole: ProjectRole;
  includeCollections: boolean;
  color?: HexColor | undefined;
  hexType: HexType;
}
export const getPermissionSummary = ({
  color = undefined,
  grantSummary,
  hexType,
  includeCollections,
  onlyGrantedToCurrentUser,
  org,
  organizationRoleV2: organizationRole,
  publicRoleV2: publicRole,
  summaryRole,
}: GetHexPermissionSummaryArgs): PermissionSummary => {
  const isComponent = hexType === HexType.COMPONENT;
  const { collectionTotal, groupTotal, userTotal } = Object.entries(
    grantSummary,
  ).reduce(
    (acc, [role, { collectionCount, groupCount, userCount }]) => {
      if (
        projectRoleBetweenBounds({
          lower: summaryRole,
          roleToCheck: role as ProjectRole,
        })
      ) {
        acc.groupTotal += groupCount ?? 0;
        acc.userTotal += userCount ?? 0;
        if (includeCollections) {
          acc.collectionTotal += collectionCount ?? 0;
        }
      }
      return acc;
    },
    { groupTotal: 0, userTotal: 0, collectionTotal: 0 },
  );

  let icon: JSX.Element;
  let text: string;
  let tooltipText: string;

  const userText = `${userTotal} user${userTotal !== 1 ? "s" : ""}`;
  const groupText = `${groupTotal} group${groupTotal !== 1 ? "s" : ""}`;
  const collectionText = `${collectionTotal} collection${
    collectionTotal !== 1 ? "s" : ""
  }`;

  if (
    org.resolvedAllowPublicSharing &&
    publicRole != null &&
    isProjectRoleSuperset(publicRole, summaryRole)
  ) {
    icon = <GlobeIcon color={color} />;
    text = "Anyone with the link";
    tooltipText = `Anyone with the link can view the ${
      isComponent
        ? "component"
        : publicRole === ProjectRole.VIEWER
          ? "entire project"
          : "published app"
    }.`;
  } else if (
    organizationRole != null &&
    isProjectRoleSuperset(organizationRole, summaryRole)
  ) {
    icon = <WorkspaceIcon color={color} />;
    text = `All of ${org.displayName}`;
    tooltipText = `The ${org.displayName} workspace`;
  } else if (
    groupTotal > 0 ||
    (userTotal > 0 && !onlyGrantedToCurrentUser) ||
    collectionTotal > 0
  ) {
    let hasPredecessorCount = false;
    icon = <UserIcon color={color} />;
    text = "";
    tooltipText = "";

    if (userTotal > 0 && !onlyGrantedToCurrentUser) {
      text = userText;
      tooltipText = userText;
      hasPredecessorCount = true;
    }
    if (groupTotal > 0) {
      text += `${hasPredecessorCount ? ", " : ""} ${groupText}`;
      tooltipText += `${hasPredecessorCount ? ", " : ""} ${groupText}`;
      hasPredecessorCount = true;
    }
    if (collectionTotal > 0) {
      if (!hasPredecessorCount) {
        icon = <CollectionIcon color={color} />;
      }
      text += `${hasPredecessorCount ? ", " : ""} ${collectionText}`;
      tooltipText += `${hasPredecessorCount ? ", " : ""} ${collectionText}`;
    }
  } else if (onlyGrantedToCurrentUser) {
    icon = <LockIcon color={color} />;
    text = "Only you";
    tooltipText = "Only you have access";
  } else if (userTotal > 0) {
    icon = <UserIcon color={color} />;
    text = userText;
    tooltipText = userText;
  } else {
    icon = <LockIcon color={color} />;
    text = "No Access";
    tooltipText = `This ${
      isComponent ? "component" : "project"
    } has not been shared with you. You can request access by clicking on the ${
      isComponent ? "component" : "project"
    }.`;
  }

  return { icon, text, tooltipText };
};

// Note: get the dialog control ID for specific modal dialog, remove overhead of manual string edits in multiple locations
export const getViewPublishedAppStatsDialogId = (): string =>
  "view-published-app-stats-dialog";

export const getConfirmHardDeleteHexDialogId = (): string =>
  "confirm-delete-hex-dialog";

export const getConfirmDeleteHexDialogId = (): string =>
  "confirm-trash-hex-dialog";

export const getConfirmAddToCollectionHexDialogId = (): string =>
  "confirm-add-to-collection-dialog";
