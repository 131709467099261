export enum ViewCountDuration {
  ONE_WEEK = "ONE_WEEK",
  TWO_WEEKS = "TWO_WEEKS",
  ONE_MONTH = "ONE_MONTH",
  ALL_TIME = "ALL_TIME",
}

export const humanReadableViewCountDuration = (
  viewCountDuration: ViewCountDuration,
): string =>
  ({
    [ViewCountDuration.ONE_WEEK]: "Last 7 days",
    [ViewCountDuration.TWO_WEEKS]: "Last 14 days",
    [ViewCountDuration.ONE_MONTH]: "Last 30 days",
    [ViewCountDuration.ALL_TIME]: "All time",
  })[viewCountDuration];
