import { Intent } from "@blueprintjs/core";
import { HexId, SpecialVersionType } from "@hex/common";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { HexMenu, HexMenuItem } from "../../../hex-components";
import { useRestoreHexMutation } from "../../../mutations/hex.generated";
import { Routes } from "../../../route/routes";
import { useDialog } from "../../../util/dialogs.js";
import { useToaster } from "../../common/Toasts";
import { EyeOpenIcon, RefreshIcon, TrashIcon } from "../../icons/CustomIcons";

import { getConfirmHardDeleteHexDialogId } from "./utils.js";

export interface TrashOptionsMenuContentsProps {
  isComponent: boolean;
  hexId: HexId;
}
export const TrashOptionsMenuContents: React.FunctionComponent<TrashOptionsMenuContentsProps> =
  React.memo(function OptionsMenu({ hexId, isComponent }) {
    const history = useHistory();
    const toaster = useToaster();
    const { openDialog } = useDialog(getConfirmHardDeleteHexDialogId());

    const [restoreHex] = useRestoreHexMutation();

    const hexTypeLabel = isComponent ? "component" : "project";

    const setHexIdQueryParam = useCallback(
      (queryParams: URLSearchParams) => {
        queryParams.set("hexId", hexId);
      },
      [hexId],
    );
    const handleOpenDialog = useCallback(() => {
      openDialog({
        beforeSetDialogQueryParam: setHexIdQueryParam,
      });
    }, [openDialog, setHexIdQueryParam]);

    const navigateToDraft = useCallback(() => {
      Routes.push(history, isComponent ? Routes.COMPONENT : Routes.LOGIC, {
        hexId,
        version: SpecialVersionType.DRAFT,
      });
    }, [hexId, history, isComponent]);

    const restoreHexCallback = useCallback(async () => {
      restoreHex({ variables: { hexId } })
        .then(() => {
          navigateToDraft();
        })
        .catch((e: Error) => {
          toaster.show({
            message: `Error restoring ${hexTypeLabel}`,
            intent: Intent.DANGER,
          });
          console.error(e);
        });
    }, [hexId, hexTypeLabel, navigateToDraft, restoreHex, toaster]);

    const stopPropagation: React.MouseEventHandler = useCallback(
      (e) => e.stopPropagation(),
      [],
    );

    return (
      <HexMenu onClick={stopPropagation}>
        <HexMenuItem
          icon={<EyeOpenIcon />}
          text={`Preview ${hexTypeLabel}`}
          onClick={navigateToDraft}
        />
        <HexMenuItem
          icon={<RefreshIcon />}
          text="Restore"
          onClick={restoreHexCallback}
        />
        <HexMenuItem
          icon={<TrashIcon />}
          intent={Intent.DANGER}
          text="Permanently delete"
          onClick={handleOpenDialog}
        />
      </HexMenu>
    );
  });

export const HexName = styled.strong`
  word-break: break-word;
`;
